/**
 * This page displays the PowerForm publisher_id_only in the DocuSign account
 * In case someone accidently changed its template setting,
 * a backup template in pur team's DocuSign developer account can be used for recover purpose,
 * Just uploaded the template to the DocuSign account, and then convert it to Powerform.
 */

import React from 'react';

import Sow from '../components/sow';

const SowPage = () => {
    return <Sow powerForm={process.env.GATSBY_DOCUSIGN_POWER_FORM_ID} />;
};

export default SowPage;
